import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

class BlogPostTemplate extends React.Component {

  render() {

    const post = this.props.data.markdownRemark
      
    const siteTitle = this.props.data.site.siteMetadata.title
    // const nextPost = this.props.pageContext.next
    // const previousPost = this.props.pageContext.previous


    return (
        
      <Layout location={this.props.location} title={siteTitle}>

        <Seo
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />

        <article
        //   className={`post-content ${post.frontmatter.thumbnail || `no-image`}`}
          className={`post-content`} 
        >

          <header className="post-content-header">
            <h1 className="post-content-title">{post.frontmatter.title}</h1>
          </header>

          {post.frontmatter.description && (
            <p className="post-content-excerpt">{post.frontmatter.description}</p>
          )}

          {post.frontmatter.thumbnail && (
            <div className="post-content-image">
              <Img
                className="kg-image"
                fluid={post.frontmatter.hero.childImageSharp.fluid}
                alt={post.frontmatter.title}
              />
            </div>
          )}

        <div className="post-content-body">

            <div className="post-content-info">

                <div className="post-content-info-client">
                    {post.frontmatter.client && 
                        <div>
                            <h2>Cliente</h2>
                            <p>{post.frontmatter.client}</p>
                        </div>
                    }   
                </div>

                <div className="post-content-info-challenge">
                    {post.frontmatter.challenge &&
                        <div>
                            <h2>Desafio</h2>
                            <p>{post.frontmatter.challenge}</p>
                        </div>
                    }
                </div>

                <div className="post-content-info-solution">
                    {post.frontmatter.solution && 
                    <div>
                        <h2>Solução</h2>
                        <p>{post.frontmatter.solution}</p>
                    </div>
                    }
                </div>

                <hr />

            </div>

            <div dangerouslySetInnerHTML={{ __html: post.html }}/>

        </div>

          <footer className="post-content-footer">

            <Link to={`/`} className="back">

                <svg className="inline" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                </svg>

                Voltar

            </Link>

              
            {/* {previousPost && <Link to={previousPost.fields.slug} rel="previous">{previousPost.frontmatter.title} </Link> } 

            <Link to={`/projetos`}>Todos</Link>

            {nextPost && <Link to={nextPost.fields.slug} rel="next"> {nextPost.frontmatter.title} </Link> } */}

          </footer>

        </article>
        
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query WorkBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        tags
        client
        challenge
        solution
        thumbnail {
          childImageSharp {
            fluid(maxWidth: 1360) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        hero {
            childImageSharp {
              fluid(maxWidth: 1360) {
                ...GatsbyImageSharpFluid
              }
            }
          }
      }
    }
  }
`
